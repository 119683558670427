.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
}

.App-header {
  background: linear-gradient(94.41deg, #2929cc 4.69%, #4242b8 78.43%);

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
