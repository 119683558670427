@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("./btncss.css"); */
/* @import url("./typography.css"); */
/* @import url("./colors.css"); */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://twemoji.maxcdn.com/v/latest/twemoji.min.js");

@layer utilities {
  @variants responsive {
    .btnPrimary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: fit-content;
      background: linear-gradient(94.41deg, #2929cc 4.69%, #4242b8 78.43%);
      border-radius: 56px;
    }

    .btnPrimary:focus {
      outline: none;
    }
    .btnWhite:focus {
      outline: none;
    }
    .btnSecondary:focus {
      outline: none;
    }

    .btnFull {
      width: 100%;
    }
    .btnFit {
      width: fit-content;
    }

    .btnSecondary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: fit-content;
      border: 1px solid #3f29cc;
      box-sizing: border-box;
      border-radius: 56px;
      background: -webkit-linear-gradient(
        94.41deg,
        #2929cc 4.69%,
        #4242b8 78.43%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .btnSmall {
      padding: 8px 24px;
    }
    .btnSmallMob {
      padding: 12px 24px;
    }

    .btnBig {
      padding: 16px 48px;
    }

    @media (hover: hover) {
      .btnPrimary:hover {
        background: linear-gradient(94.41deg, #080829 4.69%, #070714 78.43%);
      }
    }
    @media (hover: hover) {
      .btnSecondary:hover {
        background: linear-gradient(94.41deg, #080829 4.69%, #070714 78.43%);
      }
    }
    @media (hover: hover) {
      .btnSecondary:hover .textGradientspan {
        background: white;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .btnSecondary:disabled {
      pointer-events: none;
      border: 1px solid #8989d5;
    }
    .btnSecondary:disabled .textGradientspan {
      background: #8989d5;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .btnPrimary:disabled {
      background: linear-gradient(
        94.41deg,
        rgba(41, 41, 204, 0.5) 4.69%,
        rgba(66, 66, 184, 0.5) 78.43%
      );
    }

    .btnWhite {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      /* padding: 8px 24px; */
      background: #c2fff4;
      border-radius: 56px;
      /* font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.02em; */
      /* White */
    }
  }
}

@layer utilities {
  @variants responsive {
    .deskH1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 68px;
      line-height: 84px;
      letter-spacing: -0.04em;
    }

    .deskH2 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
    }

    .deskH3 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 46px;
      line-height: 56px;
    }
    .deskH4 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.02em;
    }
    .deskH5 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.02em;
    }
    .deskP {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
    }
    .deskPB {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
    .deskCTA-BIG {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
    .deskCTA-SMALL {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
    .deskCTA-HL {
      font-family: IBM Plex Sans;
      font-style: italic;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
    }

    .mobH1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 52px;
      letter-spacing: -0.04em;
    }
    .mobH2 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.02em;
    }
    .mobH3 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.02em;
    }
    .mobH4 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
    .mobH5 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      letter-spacing: 0.02em;
    }
    .mobP {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
    .mobPB {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .mobCTA-BIG {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.02em;
    }
    .mobCTA-SMALL {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
    .mobCTA-HL {
      font-family: IBM Plex Sans;
      font-style: italic;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
    }
  }
}

@layer utilities {
  .navLinks {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    line-height: 24px;
    margin: 0;
    background: -webkit-linear-gradient(
      94.41deg,
      #2929cc 4.69%,
      #4242b8 78.43%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .navLinks:hover {
    background: linear-gradient(94.41deg, #080829 4.69%, #070714 78.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bgCyan {
    background: #c2fff4;
  }
  .bgCyan25 {
    background: rgba(194, 255, 244, 0.25);
  }
  .textCyan {
    color: #c2fff4;
  }
  .bgWhite {
    background: #ffffff;
  }
  .textWhite {
    color: #ffffff;
  }
  .bgBlack {
    background: #040414;
  }

  .textBlack {
    color: #040414;
  }
  .textHashtag {
    color: #1d9bf0;
  }

  .bgGradient {
    background: linear-gradient(94.41deg, #2929cc 4.69%, #4242b8 78.43%);
  }

  .textGradient {
    background: -webkit-linear-gradient(
      94.41deg,
      #2929cc 4.69%,
      #4242b8 78.43%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .grad {
    background: -webkit-linear-gradient(
      94.41deg,
      #2929cc 4.69%,
      #4242b8 78.43%
    );
    -webkit-background-clip: text;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    background: -webkit-linear-gradient(
      94.41deg,
      #2929cc 4.69%,
      #4242b8 78.43%
    );
    -webkit-text-fill-color: transparent;
  }

  .bodybg {
    margin: 0;
    background: radial-gradient(
      89.83% 89.83% at 4.86% 89.83%,
      #c2fff4 0%,
      #ffffff 49.27%
    );
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100vh;
    max-width: 100vw;
    z-index: -100;
    position: fixed;
  }

  .overlaymenu {
    top: -500%;
    left: -500%;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100vh;
    max-width: 100vw;
    background: linear-gradient(94.41deg, #2929cc 4.69%, #4242b8 78.43%);
    z-index: 100;
    position: fixed;
    overflow: scroll;
  }

  .gradientBorder {
    border: 1px solid #3f29cc;
  }

  .cardBorder {
    border: 0.4px solid #3f29cc;
  }

  .out {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: fit-content;
  }

  /* .hidden {
    display: none;
  } */

  input[type="radio"]:checked + .out {
    border: 1px solid #2f00ff;
  }

  .navLinksOverlay {
    font-family: "IBM Plex Sans";
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  .boxStory {
    width: 100%;
    height: min-content;
    position: relative;
    /* height: 100%; */
    padding-top: 177.7777777778%;
    background-color: magenta;
  }
  .boxSquare {
    width: 100%;
    height: min-content;
    position: relative;
    /* height: 100%; */
    padding-top: 100%;
    background-color: magenta;
  }

  .tweettextbox {
    top: -1000;
    left: -1000;
    z-index: -1;
    position: fixed;
    background-color: transparent;
    border: 1px solid blue;
  }

  .tweettext {
    white-space: pre-wrap;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    background-color: transparent;
    width: 448px;
    height: 306px;
    color: #000000;
  }

  .spantext {
    background-color: transparent;
    white-space: pre-wrap;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
  }

  img.twemoji {
    display: inline;
    white-space: normal;
    height: 1em;
    width: 1em;
    margin: 0 0.1em 0 0.1em;
    vertical-align: -0.1em;
    /* vertical-align: -0.1em; */
  }

  .fullbg {
    display: flex;
    flex-direction: column;
    justify-btent: center;
    align-items: center;
    padding: 40px 120px;
    position: relative;
    width: 1080px;
    max-width: 1080px;
    max-width: 1080px;
    height: 1080px;
    flex-grow: initial;
    background-color: #3d5666;
  }
}
